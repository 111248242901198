import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";

const GalleryItem = ({ item }) => {
  return (
    <div className="mb-8">
      <figure>
        <GatsbyImage
          alt={item.altText || "gallery image"}
          image={item.gatsbyImage}
        />
      </figure>
      <div dangerouslySetInnerHTML={{ __html: item.caption }} />
    </div>
  );
};

const Gallery = ({ items }) => {
  const [activeGallery, setActiveGallery] = useState(items[0]);

  return (
    <div>
      <div className="flex flex-wrap justify-center mb-4">
        {items.map(({ node }) => {
          return (
            <button
              className={`text-white text-sm mx-3 px-4 py-3 no-underline rounded uppercase ${
                activeGallery.node.id === node.id
                  ? `bg-${process.env.THEME_COLOR_PRIMARY}`
                  : "bg-grey"
              }`}
              key={node.id}
              onClick={() => {
                setActiveGallery(
                  items.find((item) => item.node.id === node.id)
                );
              }}
            >
              {node.title}
            </button>
          );
        })}
      </div>

      <div className="columns-1 gap-5 sm:columns-2 sm:gap-8 md:columns-3">
        {activeGallery.node.gallery.gallery.length
          ? activeGallery.node.gallery.gallery.map((item, index) => {
              return <GalleryItem item={item} index={index} key={item.id} />;
            })
          : null}
      </div>
    </div>
  );
};

const GalleryPage = () => {
  const data = useStaticQuery(graphql`
    query GalleryQuery {
      allWpGallery {
        edges {
          node {
            title
            id
            gallery {
              gallery {
                id
                caption
                altText
                gatsbyImage(width: 800, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <div className="max-w-2xl m-auto pb-16 page-content">
        <div className="my-8 text-grey-darker">
          <h1>Galleries</h1>
        </div>
        {data.allWpGallery.edges ? (
          <Gallery items={data.allWpGallery.edges} />
        ) : (
          <p>There are no galleries at this time</p>
        )}
      </div>
    </>
  );
};

export default GalleryPage;
